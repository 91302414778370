import React, { useState } from "react";
import chevronUp from "assets/chevron-up-orange.svg";
import chevronDown from "assets/chevron-down-white.svg";
import Text from "components/Text";

function Accordion({ heading, description }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="w-11/12 max-w-5xl mx-auto mb-6">
      <div
        className="flex py-5 px-4  bg-[#00245A] justify-between items-center cursor-pointer rounded pl-[20px] pr-[20px] lg:pl-[42px] lg:pr-[20px]"
        onClick={() => setIsActive((active) => !active)}
      >
        <Text
          as="h3"
          className="text-xl sm:text-[22px] leading-8 text-white font-secondary my-auto mr-4"
        >
          {heading}
        </Text>
        <img
          src={isActive ? chevronUp : chevronDown}
          alt="open"
          className="w-8 h-auto basis-8"
        />
      </div>

        <div className={`py-10 ${isActive ? "block" : "hidden"} px-5 lg:px-[42px]`}>
          <Text as="div" className="text-lg leading-8 text-gray font-opensans">
            {description}
          </Text>
        </div>
  
      
    </div>
  );
}

export default Accordion;
